import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import { AbstractModal } from '@component/Modal';
import { EModal } from '@contract/modal';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
let AccountNotFoundModal = class AccountNotFoundModal extends AbstractModal {
    /**
     * Handles the `@click` event on the "log in" button.
     */
    onTryAgainBtnClick() {
        this.swapModal(EModal.ForgotPassword);
    }
};
AccountNotFoundModal = __decorate([
    Component({ name: 'AccountNotFoundModal' })
], AccountNotFoundModal);
export { AccountNotFoundModal };
export default AccountNotFoundModal;
